/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable eqeqeq */
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SyncIcon from '@material-ui/icons/Sync'
import classNames from 'classnames'
import Divider from '@material-ui/core/Divider'

import { fetchFinancialDashboard } from '../FinancialDashboardActions'
import styles from '../../../resources/theme/global'
import DashBar from '../Chart'

import Card from './Card'
import Pagination from './Pagination'
import BankCard from './BankCard'
import ReceiptCard from './ReceiptCard'
import SummaryCard from './SummaryCard'
import PeriodFilter from '../../../components/hooks/BaseButtonGroup'
import { format } from 'date-fns'
import colors from '../../../assets/colors'
import Chart from 'react-google-charts'
import { DatePicker } from '@material-ui/pickers'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import {
  mdiCalendarCheck,
  mdiCalendarMultipleCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiInformation,
  mdiInformationBoxOutline,
  mdiInformationSymbol,
  mdiMenuLeft,
  mdiMenuRight,
} from '@mdi/js'
import Icon from '@mdi/react'
import RoiInformationModal from './RoiInformationModal'

const useStyles = makeStyles({
  boxChart: {
    border: `1px solid ${colors.graylight}`,
    borderRadius: '10px',
    padding: '12px',
    fontSize: '14px',
    lineHeight: '14px',
    height: '100%',
    color: colors.gray,
  },
  boxChartTitle: {
    fontSize: '20px',
    lineHeight: '20px',
    color: colors.primary,
  },
  filterBox: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '14px',
    lineHeight: '14px',
    color: colors.gray,
  },
  periodFilter: {
    color: colors.primary,
    display: 'flex',
    minWidth: '210px',
    padding: '4px',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${colors.graylight}`,
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '14px',
  },
  periodFilterBtn: {
    padding: 0,
    transition: 'all .4s',
    color: colors.primary,
    '&:hover': {
      opacity: '.7',
      backgroundColor: '#FFF',
    },
  },
})

function FinancialDashboard(props) {
  const styles = useStyles()
  const dispatch = useDispatch()
  const data = useSelector(state => state.financialDashboard)

  const [date, setDate] = useState(new Date())
  const [value, setValue] = useState(0)
  const [roiOpen, setRoiOpen] = useState(false)

  const today = new Date()

  const [filters, setFilters] = useState({
    referenceYear: format(today, 'yyyy-MM-dd'),
  })

  const TOTAL_LINK = `/billing`

  const getFinalPath = (type, typeGeneric) => {
    const filters = {
      reference_month: format(date, 'yyyy-MM-dd'),
      period: type,
      type: typeGeneric,
      billing_type_generic: typeGeneric,
      year: value == 1 || false,
    }

    let urlParams = ''

    Object.entries(filters).forEach(([key, value]) => {
      urlParams += `${key}=${value}&`
    })

    return `${TOTAL_LINK}?${urlParams}`
  }

  const getFilter = () => {
    let filters = {}

    if (value == 0) {
      filters = { month: date.toLocaleDateString('en-us') }
    }
    if (value == 1) {
      filters = { year: format(date, 'yyyy-MM-dd') }
    }

    return filters
  }

  // control date by tab value = 1 == Anual
  const handleChangeDate = (prev = false) => {
    if (!prev) {
      if (value == 0) {
        setDate(
          prevDate => new Date(prevDate.setMonth(prevDate.getMonth() + 1))
        )
      }

      if (value == 1) {
        setDate(
          prevDate => new Date(prevDate.setFullYear(prevDate.getFullYear() + 1))
        )
      }
    }

    if (prev) {
      if (value == 0) {
        setDate(
          prevDate => new Date(prevDate.setMonth(prevDate.getMonth() - 1))
        )
      }

      if (value == 1) {
        setDate(
          prevDate => new Date(prevDate.setFullYear(prevDate.getFullYear() - 1))
        )
      }
    }
  }

  const onClickNext = () => {
    handleChangeDate()
    const filters = getFilter()
    dispatch(fetchFinancialDashboard(filters))
  }

  const onClickPrev = () => {
    handleChangeDate(true)
    const filters = getFilter()
    dispatch(fetchFinancialDashboard(filters))
  }

  const handleOpenRoiInformation = () => {
    setRoiOpen(!roiOpen)
  }

  useEffect(() => {
    const filters = getFilter()

    dispatch(fetchFinancialDashboard(filters))
  }, [value])

  return (
    <Grid container spacing={3}>
      <RoiInformationModal
        isOpen={roiOpen}
        handleClose={handleOpenRoiInformation}
      />

      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Dashboard Financeiro
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Tabs>
          <Tab
            label="Mensal"
            icon={mdiCalendarCheck}
            value={value}
            setValue={setValue}
            tabIndex={0}
          />
          <Tab
            label="Anual"
            icon={mdiCalendarMultipleCheck}
            value={value}
            setValue={setValue}
            tabIndex={1}
            removeNavigation={false}
          />
        </Tabs>
      </Grid>

      {value == 0 && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box className={styles.filterBox}>
                <span>Selecione um mês:</span>

                <Box className={styles.periodFilter}>
                  <IconButton
                    onClick={onClickPrev}
                    className={styles.periodFilterBtn}
                  >
                    <Icon path={mdiMenuLeft} size={0.7} />
                  </IconButton>

                  <Typography>{data?.currentMonth}</Typography>

                  <IconButton
                    onClick={onClickNext}
                    className={styles.periodFilterBtn}
                  >
                    <Icon path={mdiMenuRight} size={0.7} />
                  </IconButton>
                </Box>
              </Box>
            </Grid>

            <Grid item sm={5} xs={12}>
              <ReceiptCard
                tipo=""
                title="Recebidos do mês"
                positive
                val1={data?.receipts}
                val2={data?.receiptsDue}
                val3={data?.receiptsLate}
                total={data?.receiptsTotal}
                link1={getFinalPath('received', 'Receita')}
                link2={getFinalPath('toReceive', 'Receita')}
                link3={getFinalPath('late', 'Receita')}
                link4={getFinalPath('receiptsTotal', 'Receita')}
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <ReceiptCard
                payment
                title="Pagamentos do mês"
                positive={false}
                val1={data?.payments}
                val2={data?.paymentsDue}
                val3={data?.paymentsLate}
                total={data?.paymentsTotal}
                link1={getFinalPath('received', 'Despesa')}
                link2={getFinalPath('toReceive', 'Despesa')}
                link3={getFinalPath('late', 'Despesa')}
                link4={getFinalPath('receiptsTotal', 'Despesa')}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <ReceiptCard
                title="Resultado"
                positive
                val1={data?.balance}
                total={data?.banksTotal}
                tipo="Total"
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={10}>
                <Box className={styles.boxChart}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between">
                        <Grid item className={styles.boxChartTitle}>
                          Receitas x Despesas
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Chart
                        data={[
                          ['Dia', 'Receitas', 'Depesas'],
                          ...data?.monthBalance,
                        ]}
                        options={{
                          curveType: 'function',
                          legend: { position: 'bottom' },
                        }}
                        chartType="LineChart"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box className={styles.boxChart} style={{ padding: '22px' }}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        gap: '8px',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        style={{
                          color: colors.primary,
                          fontSize: '20px',
                          lineHeight: '20px',
                        }}
                      >
                        Serviços <br />
                        realizados
                      </Typography>
                      <Typography
                        style={{
                          color: colors.primary,
                          fontSize: '22px',
                          lineHeight: '22px',
                          fontWeight: 'bold',
                        }}
                      >
                        {data?.roi?.services}
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        border: '1px dashed',
                        borderColor: colors.graylight,
                      }}
                    />

                    <Box
                      style={{
                        display: 'flex',
                        gap: '8px',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        style={{
                          color: colors.primary,
                          fontSize: '20px',
                          lineHeight: '20px',
                        }}
                      >
                        ROI
                      </Typography>
                      <Typography
                        style={{
                          color: colors.primary,
                          fontSize: '22px',
                          lineHeight: '22px',
                          fontWeight: 'bold',
                        }}
                      >
                        {data?.roi?.roi || 0} %
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6}>
                <Box className={styles.boxChart}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between">
                        <Grid item className={styles.boxChartTitle}>
                          Fluxo de Caixa
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <DashBar
                        isYear={false}
                        monthBalance={data?.monthBalance}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              {data.banksBalance?.map(item => {
                return (
                  <Grid item md={2} sm={4} xs={12}>
                    <BankCard
                      total={item.balance}
                      tipo={`Saldo ${item.name}`}
                      positive={item.balance >= 0}
                    />
                  </Grid>
                )
              })}

              <Grid item md={2} sm={4} xs={12}>
                <BankCard
                  total={data?.banksTotal}
                  tipo="Total saldo"
                  positive={data?.banksTotal >= 0}
                  justRight
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {value == 1 && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box className={styles.filterBox}>
                  <span>Selecione um ano:</span>

                  <Box className={styles.periodFilter}>
                    <IconButton
                      onClick={onClickPrev}
                      className={styles.periodFilterBtn}
                    >
                      <Icon path={mdiMenuLeft} size={0.7} />
                    </IconButton>

                    <Typography>{data?.currentMonth}</Typography>

                    <IconButton
                      onClick={onClickNext}
                      className={styles.periodFilterBtn}
                    >
                      <Icon path={mdiMenuRight} size={0.7} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>

              <Grid item sm={5} xs={12}>
                <ReceiptCard
                  tipo=""
                  title="Recebimentos Anual"
                  positive
                  val1={data?.receipts}
                  val2={data?.receiptsDue}
                  val3={data?.receiptsLate}
                  total={data?.receiptsTotal}
                  link1={getFinalPath('received', 'Receita')}
                  link2={getFinalPath('toReceive', 'Receita')}
                  link3={getFinalPath('late', 'Receita')}
                  link4={getFinalPath('receiptsTotal', 'Receita')}
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <ReceiptCard
                  tipo=""
                  payment
                  title="Pagamentos Anual"
                  val1={data?.payments}
                  val2={data?.paymentsDue}
                  val3={data?.paymentsLate}
                  total={data?.paymentsTotal}
                  link1={getFinalPath('received', 'Despesa')}
                  link2={getFinalPath('toReceive', 'Despesa')}
                  link3={getFinalPath('late', 'Despesa')}
                  link4={getFinalPath('receiptsTotal', 'Despesa')}
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <ReceiptCard
                  title="Resultado"
                  positive
                  val1={data?.balance}
                  total={data?.banksTotal}
                  tipo="Total"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Box className={styles.boxChart}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                          <Grid item className={styles.boxChartTitle}>
                            Receitas x Despesas
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {data?.monthBalance?.length ? (
                          <Chart
                            data={[
                              ['Dia', 'Receitas', 'Depesas'],
                              ...data?.monthBalance,
                            ]}
                            options={{
                              curveType: 'function',
                              legend: { position: 'bottom' },
                            }}
                            chartType="LineChart"
                          />
                        ) : (
                          <CircularProgress
                            style={{ marginTop: '1em' }}
                            color="secondary"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Box className={styles.boxChart} style={{ padding: '22px' }}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                        position: 'relative',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          gap: '8px',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          style={{
                            color: colors.primary,
                            fontSize: '20px',
                            lineHeight: '20px',
                          }}
                        >
                          Serviços <br />
                          realizados
                        </Typography>
                        <Typography
                          style={{
                            color: colors.primary,
                            fontSize: '22px',
                            lineHeight: '22px',
                            fontWeight: 'bold',
                          }}
                        >
                          {data?.roi?.services}
                        </Typography>
                      </Box>

                      <Box
                        style={{
                          border: '1px dashed',
                          borderColor: colors.graylight,
                        }}
                      />

                      <Box
                        style={{
                          display: 'flex',
                          gap: '8px',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          style={{
                            color: colors.primary,
                            fontSize: '20px',
                            lineHeight: '20px',
                          }}
                        >
                          ROI
                        </Typography>
                        <Typography
                          style={{
                            color: colors.primary,
                            fontSize: '22px',
                            lineHeight: '22px',
                            fontWeight: 'bold',
                          }}
                        >
                          {data?.roi?.roi || 0} %
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6}>
                  <Box className={styles.boxChart}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                          <Grid item className={styles.boxChartTitle}>
                            Relátorio resultado anual
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <DashBar
                          referenceYear={format(date, 'yyyy-MM-dd')}
                          isYear
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                {data.banksBalance?.map(item => {
                  return (
                    <Grid item md={2} sm={4} xs={12}>
                      <BankCard
                        total={item.balance}
                        tipo={`Saldo ${item.name}`}
                        positive={item.balance >= 0}
                      />
                    </Grid>
                  )
                })}

                <Grid item md={2} sm={4} xs={12}>
                  <BankCard
                    total={data?.banksTotal}
                    tipo="Total saldo"
                    positive={data?.banksTotal >= 0}
                    justRight
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
    // <>

    //   <Container maxWidth="md">
    //     <Box paddingBottom={0} paddingTop={2}>
    //       <Pagination
    //         elevation={2}
    //         nextPage={() => onClickNext()}
    //         prevPage={() => onClickPrev()}
    //         classes={classes}
    //         text={data?.currentMonth}
    //         disabled={refreshing}
    //       />
    //     </Box>
    //   </Container>

    //   <Grid container spacing={1} className={classes.financialCard}>
    //     <Grid item xs={12} md={6} lg={4}>
    //       <Card
    //         total={data?.totalDespesas}
    //         tipo="Despesas"
    //         today={data?.despesasToday}
    //       />
    //     </Grid>
    //     <Grid item xs={12} md={6} lg={4}>
    //       <Card
    //         total={data?.totalReceitas}
    //         tipo="Receitas"
    //         positive
    //         today={data?.receitasToday}
    //       />
    //     </Grid>
    //     <Grid item xs={12} md={6} lg={4}>
    //       <Card
    //         total={data?.balance}
    //         tipo="Balanço"
    //         positive={data?.balance >= 0}
    //         today={data?.balanceToday}
    //         isBalanceCard
    //       />
    //     </Grid>
    //   </Grid>

    //   <Divider variant="middle" style={{ margin: '50px' }} />

    //   <Grid
    //     container
    //     xs
    //     justify="center"
    //     style={{ marginTop: '80px', marginBottom: '80px' }}
    //   >
    //     <Typography item variant="h4" color="secondary">
    //       Resumo Mês Atual
    //     </Typography>
    //   </Grid>

    //   <Grid container spacing={1} className={classes.financialCard}>
    //     <Grid item xs={12} md={6} lg={4}>
    //       <SummaryCard
    //         total={data?.banksTotal + data?.receiptsTotal}
    //         tipo="Saldo Bancário + Recebimentos"
    //         positive
    //       />
    //     </Grid>
    //     <Grid item xs={12} md={6} lg={4}>
    //       <SummaryCard
    //         total={data?.paymentsTotal}
    //         tipo="Pagamentos"
    //         positive={false}
    //       />
    //     </Grid>
    //     <Grid item xs={12} md={6} lg={4}>
    //       <SummaryCard
    //         total={data?.banksTotal + data?.receiptsTotal - data?.paymentsTotal}
    //         tipo="Balanço Geral"
    //         positive={
    //           data?.banksTotal + data?.receiptsTotal - data?.paymentsTotal >= 0
    //         }
    //       />
    //     </Grid>
    //   </Grid>

    //   <Divider variant="middle" style={{ margin: '50px' }} />

    //   <Grid item xs>
    //     <Typography variant="h5" color="secondary">
    //       Saldos
    //     </Typography>
    //   </Grid>
    //   <Grid
    //     container
    //     spacing={1}
    //     justify="center"
    //     justifyContent="center"
    //     alignItems="center"
    //     style={{ marginBottom: '40px' }}
    //   >
    //     <Grid
    //       container
    //       spacing={1}
    //       justify="center"
    //       justifyContent="center"
    //       alignItems="center"
    //       className={classes.financialCard}
    //       xs={9}
    //     >
    //       {data.banksBalance?.map(item => {
    //         return (
    //           <Grid item xs={4}>
    //             <BankCard
    //               total={item.balance}
    //               tipo={item.name}
    //               positive={item.balance >= 0}
    //             />
    //           </Grid>
    //         )
    //       })}
    //     </Grid>
    //   </Grid>

    //   <Grid
    //     container
    //     spacing={1}
    //     justify="center"
    //     alignItems="center"
    //     style={{ marginBottom: '40px' }}
    //   >
    //     <Grid
    //       container
    //       spacing={1}
    //       justify="flex-end"
    //       alignItems="center"
    //       className={classes.financialCard}
    //       xs={9}
    //     >
    //       <Grid item xs={12}>
    //         <BankCard
    //           total={data?.banksTotal}
    //           tipo="Total"
    //           positive={data?.banksTotal >= 0}
    //           justRight
    //         />
    //       </Grid>
    //     </Grid>
    //   </Grid>

    //   <Divider variant="middle" style={{ margin: '50px' }} />

    //   <Grid item xs>
    //     <Typography variant="h5" color="secondary">
    //       Recebimentos
    //     </Typography>
    //   </Grid>

    //   <Grid container direction="row" justify="center" alignItems="center">
    //     <Grid item xs={12} md={6} lg={9} style={{ marginBottom: '20px' }}>
    //       <ReceiptCard
    //         total={data?.balance}
    //         tipo=""
    //         positive
    //         val1={data?.lateTotal}
    //         val2={data?.dueTotal}
    //         val3={data?.receiptsTotal}
    //         link1={`${TOTAL_LINK}date_end=${dateLate}`}
    //         link2={`${TOTAL_LINK}date=${dateNow}&date_end=${dateEndMonth}`}
    //         link3={`${TOTAL_LINK}date_end=${dateEndMonth}`}
    //       />
    //     </Grid>
    //   </Grid>

    //   <Divider variant="middle" style={{ margin: '50px' }} />

    //   <Grid item xs>
    //     <Typography variant="h5" color="secondary">
    //       Pagamentos
    //     </Typography>
    //   </Grid>

    //   <Grid
    //     container
    //     spacing={1}
    //     justify="center"
    //     alignItems="center"
    //     className={classes.financialCard}
    //   >
    //     <Grid item xs={12} md={6} lg={9} style={{ marginBottom: '20px' }}>
    //       <ReceiptCard
    //         total={data?.balance}
    //         tipo=""
    //         positive={false}
    //         val1={data?.paymentsLate}
    //         val2={data?.paymentsDue}
    //         val3={data?.paymentsTotal}
    //         link1={`${PAYMENTS_LINK}date_end=${dateLate}`}
    //         link2={`${PAYMENTS_LINK}date=${dateNow}&date_end=${dateEndMonth}`}
    //         link3={`${PAYMENTS_LINK}date_end=${dateEndMonth}`}
    //       />
    //     </Grid>
    //   </Grid>

    //   <Divider variant="middle" style={{ margin: '50px' }} />

    //   <DashBar />

    //   <Divider variant="middle" style={{ margin: '50px' }} />
    // </>
  )
}

export default withStyles(styles)(FinancialDashboard)
