/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
import React, { useCallback, useEffect, useRef } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  Divider,
  Tooltip,
  Checkbox,
  makeStyles,
  Paper,
  withStyles,
  ClickAwayListener,
  MenuList,
  Grow,
  Popper,
  MenuItem,
} from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import HistoryIcon from '@material-ui/icons/History'
import ImageIcon from '@material-ui/icons/Image'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import LaunchIcon from '@material-ui/icons/Launch'
import { useConfirm } from 'material-ui-confirm'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import RIESelect from '../../components/RIESelect'
import { exportFormatedMoney, renderRouteRole } from '../../../util/utils'
import NumberFormatCustom from '../../components/NumberFormat'
import {
  downloadImagesAsZip,
  fetchAttendanceInit,
  fetchCommissionedPersons,
} from '../AttendanceActions'

import TimeModalInfo from './TimeModalInfo'
import ItemHistory from './ItemHistory'
import colors from '../../../assets/colors'
import {
  mdiDelete,
  mdiDotsVertical,
  mdiHistory,
  mdiImage,
  mdiLaunch,
} from '@mdi/js'
import Icon from '@mdi/react'
import fontSizes from '../../../assets/fontSizes'
import { pl } from 'date-fns/locale'
import SelectField from '../../components/SelectField'

const useStyles = makeStyles({
  // input: {
  //   '& input[type=number]': {
  //     '-moz-appearance': 'textfield',
  //   },
  //   '& input[type=number]::-webkit-outer-spin-button': {
  //     '-webkit-appearance': 'none',
  //     margin: 0,
  //   },
  //   '& input[type=number]::-webkit-inner-spin-button': {
  //     '-webkit-appearance': 'none',
  //     margin: 0,
  //   },
  // },
  paper: {
    marginRight: 0,
    borderRadius: '10px',
    color: colors.textPrimary,
    fontSize: fontSizes.text,
    boxShadow: '0px 0px 7px 0px #00000040',
  },
  ref: {
    width: '24px',
    height: '24px',
    position: 'absolute',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    right: '-7px',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
})

const StyledTableCellBody = withStyles(theme => ({
  head: {
    backgroundColor: '#FFFF',
    color: colors.textPrimary,
    borderColor: colors.secondary,
  },
  body: {
    fontSize: 14,
    padding: '8px 12px',
    color: colors.textPrimary,
    borderBottom: `1px dashed ${colors.graylight}`,
  },
}))(TableCell)

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#FFFF',
    color: colors.primary,
    padding: '12px 0px',
    // border: '2px',
    borderColor: colors.secondary,
  },
  body: {
    fontWeight: 700,
    fontSize: 14,
  },
}))(TableCell)

function TableServices(props) {
  const styles = useStyles()

  const [open, setOpen] = React.useState(false)
  const [info, setInfo] = React.useState('')
  const [removing, setRemoving] = React.useState(null)
  const [hoverId, setHoverId] = React.useState(null)

  const [itemHistoryOpen, setItemHistoryOpen] = React.useState(false)
  const [itemInfoOpen, setItemInfoOpen] = React.useState(false)
  const [item, setItem] = React.useState('')

  const role = useSelector(state => state.auth.role)
  const itemStatuses = useSelector(state => state.attendance.itemStatuses)
  const commissionedPersons = useSelector(
    state => state.attendance.commissionedPersons
  )

  const {
    classes,
    items,
    removeChildren,
    updateChildren,
    discountDisabled,
    openEdit,
    updateFinalTime,
    updateItemFinalValue,
    formId,
    updateFieldSelect,
    rework,
    setAllToRework = () => {},
    commission,
    allInRework,
  } = props
  const confirm = useConfirm()
  const dispatch = useDispatch()

  const anchorRefs = useRef([])

  const handleToggle = id => {
    if (open?.id === id) {
      return setOpen({
        id: '',
      })
    }

    setOpen({
      ...open,
      id: id,
    })
  }

  const handleClosePopUp = (event, index) => {
    if (
      anchorRefs.current[index] &&
      anchorRefs.current[index].contains(event.target)
    ) {
      return
    }

    setOpen({
      id: '',
    })
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen({
        id: '',
      })
    }
  }

  const confirmRemove = id => {
    confirm({
      description: 'Deseja remover o serviço da lista?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      setRemoving(id)
      setTimeout(() => {
        removeChildren(id)
        setRemoving(null)
      }, 400)
    })
  }

  const handleOpenInfo = () => {
    setInfo()
    setItemInfoOpen(false)
  }

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const openTimeInfoModal = id => {
    setInfo(id)
    setItemInfoOpen(true)
  }

  const handleItemHistoryOpen = () => {
    setItemHistoryOpen(true)
  }

  const handleItemHistoryClose = useCallback(() => {
    setItemHistoryOpen(false)
  }, [])

  const openHistoryItem = id => {
    setItem(id)
    handleItemHistoryOpen()
  }

  const confirmDownloadImages = id => {
    confirm({
      description: 'Deseja baixar as imagens do serviço?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(downloadImagesAsZip(id))
    })
  }

  const renderTooltip = index => {
    // console.log(items[index])

    const { options } = items[index]
    if (options.type === 1) {
      return `Base: ${options.base} - Altura: ${options.altura}`
    } else if (options.type === 2) {
      return `Diâmetro: ${options.diametro}`
    }

    return ''
  }

  const updateCommissionedPerson = (index, person) => {
    updateChildren(index, person, 'commissioned_person')
  }

  useEffect(() => {
    dispatch(fetchCommissionedPersons())
  }, [])

  return (
    <>
      <TimeModalInfo
        handleClose={handleOpenInfo}
        open={itemInfoOpen}
        items={items}
        info={info}
        classes={classes}
        setHoverId={setHoverId}
        updateChildren={updateChildren}
      />

      <ItemHistory
        item={item}
        handleClose={handleItemHistoryClose}
        open={itemHistoryOpen}
      />

      <TableContainer>
        <Table
          // component="table"
          // stickyHeader
          style={{ overflow: 'hidden' }}
          // className={styles.table}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="start">Descrição</StyledTableCell>
              <StyledTableCell align="center">Qtde</StyledTableCell>
              <StyledTableCell align="center">Tipo</StyledTableCell>
              <StyledTableCell align="center">Total H</StyledTableCell>
              <StyledTableCell align="center">Valor</StyledTableCell>
              <StyledTableCell align="center">Desc.%</StyledTableCell>
              <StyledTableCell align="center">Total</StyledTableCell>
              {/* {renderRouteRole(
                ['administrador', 'gerente_lavanderia'],
                role
              ) ? (
              ) : (
                ''
              )} */}
              <StyledTableCell align="center">Status</StyledTableCell>
              {commission && (
                <StyledTableCell align="center" style={{ minWidth: '130px' }}>
                  Comissão
                </StyledTableCell>
              )}
              {rework && (
                <StyledTableCell align="center" style={{ minWidth: '120px' }}>
                  <Checkbox
                    checked={allInRework}
                    key={`checkbox-rework-${allInRework}`}
                    onChange={e => setAllToRework(e.target.checked)}
                  />
                  Retrabalho
                </StyledTableCell>
              )}
              <StyledTableCell align="center">Ações</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item, key) => {
              return (
                <TableRow
                  key={item.dateTime || item.id}
                  className={classNames({
                    [classes.attendanceRowLeft]: key % 2 === 0,
                    [classes.attendanceRowRight]: key % 2 === 1,
                    [classes.removeAttendanceLine]: removing === key,
                    [classes.hoverRestoration]:
                      hoverId && hoverId === item?.bar_code,
                  })}
                >
                  <StyledTableCellBody
                    align="start"
                    style={{ maxWidth: '200px' }}
                  >
                    {item?.name}
                  </StyledTableCellBody>
                  <StyledTableCellBody align="center">
                    {[1, 4].includes(item?.measureType?.id) && (
                      <TextField
                        onChange={event =>
                          updateChildren(key, event.target.value, 'quantity')
                        }
                        // type="number"
                        value={item?.quantity}
                        inputProps={{
                          min: '0',
                          style: {
                            textAlign: 'center',
                            maxWidth: '36px',
                          },
                        }}
                      />
                    )}

                    {item?.measureType?.id === 2 && (
                      <Tooltip
                        title={renderTooltip(key)}
                        aria-label="tooltip"
                        style={{ textAlign: 'center', maxWidth: '36px' }}
                      >
                        <TextField
                          onClick={() => openEdit(key)}
                          value={item.quantity}
                          inputProps={{
                            min: '0',
                            style: {
                              textAlign: 'center',
                              maxWidth: '36px',
                              cursor: 'pointer',
                            },
                          }}
                        />
                      </Tooltip>
                    )}
                    {item?.measureType?.id === 3 && (
                      <TextField
                        onChange={event =>
                          updateChildren(key, event.target.value, 'quantity')
                        }
                        // type="number"
                        placeholder="0"
                        className={styles?.input}
                        value={item?.quantity}
                        inputProps={{
                          min: '0',
                          style: {
                            textAlign: 'center',
                            maxWidth: '36px',
                          },
                        }}
                      />
                    )}
                  </StyledTableCellBody>

                  <StyledTableCellBody align="center">
                    {item?.measureType.name == 'Unidade' && 'Unit'}
                    {item?.measureType.name == 'Metro Linear' && 'M Lin.'}
                    {item?.measureType.name == 'Metro Quadrado' && 'M²'}
                    {item?.measureType.name == 'Quilos' && 'Kg'}
                  </StyledTableCellBody>

                  <StyledTableCellBody align="center">
                    <InputMask
                      style={{ width: '50px' }}
                      mask="99:99"
                      formatChars={{ 9: '[0-9]', '?': '[0-9/.]' }}
                      maskChar=""
                      value={item?.final_time}
                      placeholder="00:30:00"
                      onChange={event =>
                        updateFinalTime(key, event.target.value)
                      }
                      inputProps={{
                        style: { textAlign: 'center' },
                      }}
                    >
                      {inputProps => <TextField {...inputProps} />}
                    </InputMask>
                  </StyledTableCellBody>

                  <StyledTableCellBody align="center">
                    {item?.serviceType?.edit_final_value ||
                    item?.edit_final_value ? (
                      <TextField
                        value={item.default_value}
                        onChange={e =>
                          updateChildren(key, e.target.value, 'default_value')
                        }
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            prefix: 'R$ ',
                            style: { textAlign: 'center', maxWidth: '116px' },
                          },
                        }}
                      />
                    ) : (
                      exportFormatedMoney(item?.default_value)
                    )}
                  </StyledTableCellBody>

                  <StyledTableCellBody align="center">
                    <InputMask
                      style={{ width: '50px' }}
                      mask="?????"
                      disabled={
                        discountDisabled != null &&
                        discountDisabled != 0 &&
                        discountDisabled != ''
                      }
                      formatChars={{ 9: '[0-9]', '?': '[0-9/.]' }}
                      maskChar=""
                      value={item?.discount}
                      placeholder="0%"
                      onChange={event =>
                        updateChildren(key, event.target.value, 'discount')
                      }
                      inputProps={{
                        style: { textAlign: 'center' },
                      }}
                    >
                      {inputProps => <TextField {...inputProps} />}
                    </InputMask>
                  </StyledTableCellBody>

                  <StyledTableCellBody align="center">
                    <TextField
                      disabled
                      placeholder="Valor"
                      value={item?.final_value}
                      onChange={e => updateItemFinalValue(key, e.target.value)}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          prefix: 'R$ ',
                          style: { textAlign: 'center', maxWidth: '100px' },
                        },
                      }}
                    />
                  </StyledTableCellBody>

                  <StyledTableCellBody align="center">
                    {/* {renderRouteRole(
                      ['administrador', 'gerente_lavanderia'],
                      role
                    ) ? (
                      <RIESelect
                        options={itemStatuses}
                        setForm={updateFieldSelect}
                        data={item?.status}
                        chave={key}
                        name="status"
                      />
                    ) : (
                    )} */}
                    {item?.status?.name || '-'}
                  </StyledTableCellBody>

                  {commission && (
                    <StyledTableCellBody align="center">
                      <SelectField
                        style={{ color: 'black', marginTop: '0px' }}
                        options={commissionedPersons}
                        setForm={person =>
                          updateCommissionedPerson(key, person)
                        }
                        data={item?.commissioned_person}
                        uniqueState
                        label="Comissionado"
                      />
                    </StyledTableCellBody>
                  )}
                  {rework && (
                    <StyledTableCellBody padding="checkbox" align="center">
                      <Checkbox
                        name="is_rework"
                        checked={Boolean(item.is_rework)}
                        onChange={e =>
                          updateChildren(key, e.target.checked, 'is_rework')
                        }
                      />
                    </StyledTableCellBody>
                  )}

                  <StyledTableCellBody
                    style={{ maxWidth: '150px' }}
                    align="center"
                  >
                    <IconButton
                      // eslint-disable-next-line no-undef
                      ref={el => (anchorRefs.current[item?.id] = el)}
                      key={item.id}
                      id={item?.id}
                      aria-controls={
                        open?.id ? `menu-list-grow-${item.id}` : undefined
                      }
                      aria-haspopup="true"
                      onClick={() => {
                        handleToggle(item.id)
                      }}
                    >
                      <Icon path={mdiDotsVertical} size={1} />
                    </IconButton>
                  </StyledTableCellBody>

                  <Popper
                    open={open?.id === item?.id}
                    role={undefined}
                    anchorEl={anchorRefs.current[item?.id]}
                    transition
                    disablePortal
                    style={{
                      zIndex: 99999999,
                    }}
                    placement="left-end"
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement == 'bottom-start'
                              ? 'left-start'
                              : 'left-end',
                        }}
                      >
                        <Paper className={styles.paper}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open?.id}
                              id={`menu-list-grow-${item.id}`}
                              onKeyDown={handleListKeyDown}
                              className={styles.menuList}
                            >
                              <MenuItem
                                className={styles.ref}
                                style={
                                  placement === 'bottom-end'
                                    ? { top: '12px' }
                                    : { bottom: '12px' }
                                }
                              />
                              <MenuItem
                                onClick={handleClose}
                                className={styles.menuItem}
                              >
                                <IconButton
                                  onClick={() => confirmRemove(key)}
                                  // title="excluir item"
                                  className={styles.menuItemLi}
                                >
                                  <Icon
                                    path={mdiDelete}
                                    size={1}
                                    className={styles.menuItemLiIcon}
                                  />
                                  Excluir
                                </IconButton>
                              </MenuItem>

                              {/* {item?.measureType?.id === 2 && (
                                <MenuItem
                                  onClick={handleClose}
                                  className={styles.menuItem}
                                >
                                  <IconButton
                                    onClick={() => openEdit(key)}
                                    className={styles.menuItemLi}
                                  >
                                    <Icon
                                      path={mdiLaunch}
                                      size={1}
                                      className={styles.menuItemLiIcon}
                                    />
                                    Medidas
                                  </IconButton>
                                </MenuItem>
                              )} */}

                              {formId && (
                                <MenuItem
                                  onClick={handleClose}
                                  className={styles.menuItem}
                                >
                                  <IconButton
                                    onClick={() => openTimeInfoModal(item.id)}
                                    className={styles.menuItemLi}
                                  >
                                    <Icon
                                      path={mdiLaunch}
                                      size={1}
                                      className={styles.menuItemLiIcon}
                                    />
                                    Inf. Item.
                                  </IconButton>
                                </MenuItem>
                              )}

                              {formId && (
                                <MenuItem
                                  onClick={handleClose}
                                  className={styles.menuItem}
                                >
                                  <IconButton
                                    onClick={() => openHistoryItem(item.id)}
                                    // title="histórico"
                                    className={styles.menuItemLi}
                                  >
                                    <Icon
                                      path={mdiHistory}
                                      size={1}
                                      className={styles.menuItemLiIcon}
                                    />
                                    Histórico
                                  </IconButton>
                                </MenuItem>
                              )}

                              {formId && (
                                <MenuItem
                                  onClick={handleClose}
                                  className={styles.menuItem}
                                >
                                  <IconButton
                                    onClick={() =>
                                      confirmDownloadImages(item.id)
                                    }
                                    // title="Download das imagens"
                                    className={styles.menuItemLi}
                                  >
                                    <Icon
                                      path={mdiImage}
                                      size={1}
                                      className={styles.menuItemLiIcon}
                                    />
                                    Download Foto
                                  </IconButton>
                                </MenuItem>
                              )}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Divider variant="fullWidth" /> */}
    </>
  )
}

export default TableServices
