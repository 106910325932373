/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'

import colors from '../../../assets/colors'
import {
  showNfseConfig,
  storeNfseConfig,
} from '../LaundryEletronicInvoiceActions'
import { SNACKBAR } from '../../main/MainActions'
import NumberFormatCustom from '../../components/NumberFormat'

const useStyles = makeStyles({
  box: {
    padding: '12px',
    borderRadius: '3px',
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.2)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  btnSave: {
    borderRadius: '20px',
    color: '#FFF',
    backgroundColor: colors.primary,
    fontSize: '12px',
    textTransform: 'capitalize',
    width: '101px',
    padding: '4px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: '.7',
    },
  },
})

const NfseConfigForm = () => {
  const dispatch = useDispatch()

  const classes = useStyles()

  const [form, setForm] = useState({
    codigo_tributario_municipio: '',
    natureza_operacao: '',
    regime_especial_tributacao: '',
    optante_simples_nacional: '',
    aliquota_iss: null,
  })

  const fetchNfseConfig = async () => {
    try {
      const resp = await showNfseConfig()

      if (resp?.data) {
        setForm({
          ...form,
          ...resp?.data,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const data = new FormData(e.target)
    data.delete('aliquota_iss')
    data.append('aliquota_iss', form?.aliquota_iss)

    try {
      const resp = await storeNfseConfig(data)

      dispatch({
        type: SNACKBAR.SOFTSUCCESS,
        success: { message: 'Sucesso!' },
      })
    } catch (e) {
      console.log(e)

      dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Erro ao atualizar configurações!' },
      })
    }
  }

  useEffect(() => {
    fetchNfseConfig()
  }, [])

  return (
    <Grid container xs={12}>
      <Grid item xs={12} className={classes.box}>
        <Typography style={{ color: colors.textSecondary }}>
          Preencha todas as configurações para gerar a nota fiscal eletrônica
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item md={2} xs={6}>
              <TextField
                name="codigo_tributario_municipio"
                label="Código Tributário do Munícipio (CTISS)"
                required
                type="number"
                fullWidth
                defaultValue={form?.codigo_tributario_municipio}
                key={form?.codigo_tributario_municipio}
              />
            </Grid>

            <Grid item md={3} xs={6}>
              <TextField
                select
                name="natureza_operacao"
                fullWidth
                label="Natureza da Operação"
                defaultValue={form?.natureza_operacao}
                key={form?.natureza_operacao}
                required
              >
                <MenuItem value="">Selecione...</MenuItem>
                <MenuItem value="1">Tributação no município</MenuItem>
                <MenuItem value="2">Tributação fora do município</MenuItem>
                <MenuItem value="3">Isenção</MenuItem>
                <MenuItem value="4">Imune</MenuItem>
                <MenuItem value="5">
                  Exigibilidade suspensa por decisão judicial
                </MenuItem>
                <MenuItem value="6">
                  Exigibilidade suspensa por procedimento administrativo
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item md={3} xs={6}>
              <TextField
                select
                name="regime_especial_tributacao"
                fullWidth
                label="Regime Especial de Tributação"
                required
                defaultValue={form?.regime_especial_tributacao}
                key={form?.regime_especial_tributacao}
              >
                <MenuItem value="">Selecione...</MenuItem>
                <MenuItem value="1">Microempresa municipal</MenuItem>
                <MenuItem value="2">Estimativa</MenuItem>
                <MenuItem value="3">Sociedade de profissionais</MenuItem>
                <MenuItem value="4">Cooperativa</MenuItem>
                <MenuItem value="5">MEI - Simples Nacional</MenuItem>
                <MenuItem value="6">ME EPP - Simples Nacional</MenuItem>
              </TextField>
            </Grid>

            <Grid item md={2} xs={6}>
              <TextField
                select
                name="optante_simples_nacional"
                fullWidth
                label="Optante pelo simples Nacional"
                required
                defaultValue={form?.optante_simples_nacional}
                key={form?.optante_simples_nacional}
              >
                <MenuItem value="">Selecione...</MenuItem>
                <MenuItem value="1">Sim</MenuItem>
                <MenuItem value="0">Não</MenuItem>
              </TextField>
            </Grid>

            <Grid item md={2} xs={12}>
              <TextField
                name="aliquota_iss"
                fullWidth
                label="Aliquota Iss"
                helperText="Somente se o municipio exigir"
                value={form?.aliquota_iss}
                onChange={e =>
                  setForm({
                    ...form,
                    aliquota_iss: e.target.value,
                  })
                }
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  inputProps: {
                    suffix: '%',
                    style: {
                      textAlign: 'left',
                      color: colors.textPrimary,
                    },
                    min: 0,
                    max: 100,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <Button type="submit" className={classes.btnSave}>
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default NfseConfigForm
