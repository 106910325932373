/* eslint-disable no-else-return */
import React, { useEffect, useRef, useState } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  withStyles,
  makeStyles,
  Chip,
  InputBase,
  InputAdornment,
  Select,
  Input,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core'
import InputMask from 'react-input-mask'
import { BiCalculator } from 'react-icons/bi'

import { duplicateTime, exportFormatedMoney } from '../../../util/utils'
import NumberFormatCustom from '../../components/NumberFormat'
import colors from '../../../assets/colors'
import Icon from '@mdi/react'
import {
  mdiCalculatorVariant,
  mdiChevronDown,
  mdiClose,
  mdiDelete,
  mdiMenuDown,
} from '@mdi/js'
import fontSizes from '../../../assets/fontSizes'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const StyledTableCellBody = withStyles(theme => ({
  head: {
    backgroundColor: 'inherit',
    color: colors.textPrimary,
  },
  body: {
    fontSize: 14,
    padding: '8px 12px',
    color: colors.textPrimary,
  },
}))(TableCell)

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'inherit',
    color: colors.primary,
    padding: '12px 0px',
    borderColor: colors.secondary,
    textAlign: 'center',
  },
  body: {
    fontWeight: 700,
    fontSize: 14,
  },
}))(TableCell)

const useStyles = makeStyles({
  table: {
    padding: '0 8px',
    borderRadius: '15px',
    backgroundColor: '#D9D9D94D',
  },
  formasPagamentoContainer: {
    margin: '1.5rem 0',
  },
  formasPagamentoBox: {
    padding: '8px 0',
    borderBottom: `2px solid ${colors.graylight}`,
  },
  chip: {
    backgroundColor: 'inherit',
    border: `2px solid ${colors.graylight}`,
    fontSize: '14px',
    height: '24px',
  },
  paper: {
    // marginRight: theme.spacing(2),
    borderRadius: '10px',
    color: colors.textPrimary,
    fontSize: fontSizes.text,
    position: 'relative',
    marginTop: '1.7em',
    boxShadow: '0px 0px 7px 0px #00000040',
  },
  buttons: {
    color: colors.primary,
  },
  ref: {
    width: '50px',
    height: '50px',
    position: 'absolute',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    top: '4px',
    left: '32%',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '6px 12px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  newPaymentMethod: {
    fontWeight: '700',
    marginTop: '10px',
    whiteSpace: 'pre-wrap',
    lineBreak: 'auto',
    height: 'auto',
    maxWidth: '139px',
    textAlign: 'start',
    fontSize: '14px',
    padding: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
})

function TotalTable({
  form,
  setForm,
  noDiscount,
  onChangeFinalValue,
  setGeneralFinalAltered,
  updateFinalValue,
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const times = []
  // let hasType2 = 0
  form.services?.map(item => {
    times.push(duplicateTime([`${item.final_time}:00`], 1))
    // if (item.measureType?.id === 1) {
    //   if (item.final_time.match(/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/)) {
    //     console.log(item.final_time)
    //     times.push(duplicateTime([item.final_time], 1))
    //   }
    // } else {
    //   if (hasType2 === 0) {
    //     times.push(duplicateTime([item.execution_time], 1))
    //   }
    //   hasType2++
    // }
  })

  // useEffect(() => {
  //   console.log(times)
  // }, [times])

  const formatTime = time => {
    const splitedTime = time.split(':')
    return `${splitedTime[0]}:${splitedTime[1]}`
  }

  return (
    <>
      <TableContainer className={classes.table}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell aling="center">Total Horas</StyledTableCell>
              <StyledTableCell aling="center">Desconto Geral</StyledTableCell>
              <StyledTableCell aling="center">
                Total geral S/ Desconto
              </StyledTableCell>
              <StyledTableCell aling="center">
                Total geral C/ Desconto
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCellBody align="center">
                {formatTime(duplicateTime(times, 1))}
              </StyledTableCellBody>
              <StyledTableCellBody align="center">
                <InputMask
                  mask="?????"
                  style={{ maxWidth: '100px' }}
                  formatChars={{ 9: '[0-9]', '?': '[0-9/.]' }}
                  maskChar=""
                  value={form.general_discount}
                  placeholder="0%"
                  onChange={e => {
                    setGeneralFinalAltered(true)
                    setForm({ ...form, general_discount: e.target.value })
                  }}
                  inputProps={{ style: { textAlign: 'center' } }}
                >
                  {inputProps => <TextField {...inputProps} fullWidth />}
                </InputMask>
              </StyledTableCellBody>
              <StyledTableCellBody align="center">
                {exportFormatedMoney(noDiscount || 0)}
              </StyledTableCellBody>
              <StyledTableCellBody align="center">
                <Box
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <TextField
                    placeholder="Valor Final"
                    value={form.final_value_discount}
                    onBlur={() => {
                      onChangeFinalValue()
                    }}
                    onChange={e => {
                      setForm({ ...form, final_value_discount: e.target.value })
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: {
                        prefix: 'R$ ',
                        style: {
                          textAlign: 'center',
                          color: colors.textPrimary,
                        },
                      },
                    }}
                  />
                  <IconButton
                    color="inherit"
                    onClick={onChangeFinalValue}
                    aria-label="Close"
                  >
                    <Icon
                      path={mdiCalculatorVariant}
                      size={1}
                      title="Calcular desconto"
                    />
                  </IconButton>
                </Box>
              </StyledTableCellBody>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {form.payment_options && (
        <Box component="div" className={classes.formasPagamentoContainer}>
          <Grid container alignItems="center" xs={12} justifyContent="flex-end">
            <Grid
              item
              xs={12}
              md={4}
              container
              spacing={2}
              direction="row"
              alignItems="center"
              className={classes.formasPagamentoBox}
            >
              <Grid item xs={4}>
                <Typography variant="span">Formas de pagamento:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      flex: '1',
                      gap: '4px',
                    }}
                  >
                    {form?.payment_options?.map(item => {
                      if (item?.checked) {
                        return (
                          <Chip
                            avatar={
                              <Icon
                                style={{ cursor: 'pointer' }}
                                path={mdiClose}
                                size={0.6}
                                onClick={() => {
                                  const newPaymentOptions =
                                    form.payment_options.map(payment => {
                                      if (payment.id === item.id) {
                                        payment.checked = !item.checked
                                      }
                                      return payment
                                    })
                                  setForm({
                                    ...form,
                                    payment_options: newPaymentOptions,
                                  })
                                }}
                              />
                            }
                            label={item?.value}
                            className={classes.chip}
                          />
                        )
                      }
                    })}
                  </Box>
                  <IconButton
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <Icon path={mdiMenuDown} size={0.8} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: 99 }}
              placement="bottom-end"
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper className={classes.paper}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                        className={classes.menuList}
                      >
                        {form?.payment_options?.map(item => {
                          if (!item?.checked) {
                            return (
                              <MenuItem
                                onClick={() => {
                                  const newPaymentOptions =
                                    form.payment_options.map(payment => {
                                      if (payment.id === item.id) {
                                        payment.checked = !item.checked
                                      }
                                      return payment
                                    })
                                  setForm({
                                    ...form,
                                    payment_options: newPaymentOptions,
                                  })
                                }}
                                className={classes.menuItem}
                              >
                                {item?.value}
                              </MenuItem>
                            )
                          }
                        })}

                        <MenuItem className={classes.menuItem}>
                          <IconButton
                            component={Link}
                            className={classes.newPaymentMethod}
                            to="/payment-method"
                          >
                            Cadastrar nova forma de pagamento
                          </IconButton>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default TotalTable
