/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import queryString from 'query-string'
import { format } from 'date-fns'

import { exportFormatedMoney } from '../../../util/utils'
import consts from '../../../util/consts'
import Paginate from '../../components/Pagination'
import { fetchServicesRelatoryInit } from '../ServicesRelatoryActions'
import {
  StyledTableCellBody,
  StyledTableCellHeader,
} from '../../../components/hooks/BaseTableComponents'
import statusColors from '../../../assets/statusColors'
import { Chip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    maxHeight: 'calc(100vh - 235px)',
  },
})

function List(props) {
  const dispatch = useDispatch()
  const filters = useSelector(state => state.services_relatory.filters)
  const [rowsPerPage, setRowsPerPage] = useState(consts.DEFAULT_PAGINATION)
  const { classes, totalSize, page } = props
  const items = useSelector(state => state.services_relatory.items)

  const styles = useStyles()

  const onMovePage = (event, newPage) => {
    const values = queryString.parse(props.location.search)

    const filtersLeads = {
      type: 'dashboardLeads',
      status: values?.status,
      finalDate: values?.finalDate,
      startDate: values?.startDate,
      filters: {
        date: format(new Date(), 'yyyy-MM-dd HH:mm'),
      },
    }

    if (values?.status) {
      dispatch(
        fetchServicesRelatoryInit(newPage + 1, filtersLeads, rowsPerPage)
      )
    } else {
      dispatch(fetchServicesRelatoryInit(newPage + 1, filters, rowsPerPage))
    }
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  return (
    <>
      <TableContainer className={styles.root}>
        <Table stickyHeader size="medium" className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <StyledTableCellHeader>Nº OS</StyledTableCellHeader>
              <StyledTableCellHeader>Atendente</StyledTableCellHeader>
              {/* <StyledTableCellHeader>Empresa</StyledTableCellHeader> */}
              <StyledTableCellHeader>Cliente</StyledTableCellHeader>
              <StyledTableCellHeader>Telefone</StyledTableCellHeader>
              <StyledTableCellHeader>Bairro</StyledTableCellHeader>
              <StyledTableCellHeader align="center">
                Status
              </StyledTableCellHeader>
              <StyledTableCellHeader>Data</StyledTableCellHeader>
              <StyledTableCellHeader>Valor Total</StyledTableCellHeader>
              <StyledTableCellHeader>Como Soube</StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <StyledTableCellBody>{item?.id}</StyledTableCellBody>
                <StyledTableCellBody>{item.attendent_name}</StyledTableCellBody>
                {/* <StyledTableCellBody>{item.company_name}</StyledTableCellBody> */}
                <StyledTableCellBody>{item.client_name}</StyledTableCellBody>
                <StyledTableCellBody>{item.phone}</StyledTableCellBody>
                <StyledTableCellBody>{item.neighborhood}</StyledTableCellBody>
                <StyledTableCellBody align="center">
                  <Chip
                    size="small"
                    label={item.status}
                    style={{
                      backgroundColor: item?.status_color,
                      borderRadius: '8px',
                      fontSize: '14px',
                      color: '#FFFF',
                    }}
                  />
                </StyledTableCellBody>
                <StyledTableCellBody>{item.date}</StyledTableCellBody>
                {/* <StyledTableCellBody>{item.hour}</StyledTableCellBody> */}
                <StyledTableCellBody>
                  {exportFormatedMoney(item.total)}
                </StyledTableCellBody>
                <StyledTableCellBody>{item.know_name}</StyledTableCellBody>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        rows={totalSize}
        page={page - 1}
        handleChangePage={onMovePage}
      />
    </>
  )
}

export default List
