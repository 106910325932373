/* eslint-disable react/prop-types */
import Box from '@material-ui/core/Box'
import { MenuItem, Typography, makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React, { useEffect, useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BadgeGlobal from '../../components/Badge'
import {
  fetchServicesRelatoryInit,
  makeRelatory,
} from '../ServicesRelatoryActions'
import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import Icon from '@mdi/react'
import { mdiFilterMultiple, mdiMicrosoftExcel } from '@mdi/js'
import colors from '../../../assets/colors'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import queryString from 'query-string'

const useStyles = makeStyles({
  modal: {
    width: '180px',
  },
})

export default function Filter(props) {
  const items = useSelector(state => state.services_relatory.items)
  const sizePerPage = useSelector(state => state.services_relatory.sizePerPage)
  const filters = useSelector(state => state.services_relatory.filters)

  const attendances = useSelector(state => state.services_relatory.attendances)
  const serviceStatus = useSelector(
    state => state.services_relatory.services_status
  )
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const dispatch = useDispatch()
  const [form, setForm] = useState({
    attendance: '',
    date_start: new Date(),
    date_end: new Date(),
    service_status: '',
  })

  const filterStyles = filterStylesComponents()

  const handleOpen = () => {
    setOpen(!open)
  }

  const onSubmit = e => {
    e.preventDefault()
    dispatch(fetchServicesRelatoryInit(null, form, sizePerPage))
  }

  const handleDownload = e => {
    e.preventDefault()
    dispatch(makeRelatory(form))
  }

  const handleSearch = e => {
    e.preventDefault()
    dispatch(fetchServicesRelatoryInit(null, form, sizePerPage))
  }

  const handleFormatDate = (date = '') => {
    if (!date) return null

    const dateSize = date.split('-')

    return dateSize?.length == 3 ? `${date} 00:00` : date
  }

  useEffect(() => {
    setForm(prev => ({
      ...prev,
      ...filters,
      date_end: handleFormatDate(filters?.finalDate) || form.date_end,
      date_start: handleFormatDate(filters?.startDate) || form.date_start,
    }))
  }, [filters])

  return (
    <>
      <BaseFilter>
        <Grid container justifyContent="space-between">
          <Grid container xs={8} md={6} spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <KeyboardDatePicker
                clearable
                value={form.date_start}
                label="Data Inicial"
                fullWidth
                views={['date']}
                clearLabel="Limpar"
                invalidDateMessage="Data inválida"
                autoOk
                onBlur={handleSearch}
                onChange={date => setForm({ ...form, date_start: date })}
                format="dd/MM/yyyy"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <KeyboardDatePicker
                clearable
                value={form.date_end}
                label="Data Final"
                fullWidth
                views={['date']}
                clearLabel="Limpar"
                onBlur={handleSearch}
                invalidDateMessage="Data inválida"
                autoOk
                onChange={date => setForm({ ...form, date_end: date })}
                format="dd/MM/yyyy"
              />
            </Grid>
          </Grid>

          <Grid container xs={4} md={6} className={filterStyles.areaButtons}>
            <IconButton
              className={filterStyles.btnMoreFilters}
              onClick={handleOpen}
            >
              <Icon
                path={mdiFilterMultiple}
                size={1}
                color={colors.secondary}
              />
              Outros Filtros
            </IconButton>

            <IconButton
              className={filterStyles.btnAdd}
              onClick={handleDownload}
            >
              <Icon path={mdiMicrosoftExcel} size={1} />
              Donwload
            </IconButton>
          </Grid>
        </Grid>
      </BaseFilter>

      <BaseFilterModal title="FILTRO" open={open} handleOpen={handleOpen}>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: '16px', fontWeight: 'normal' }}
            color={colors.primary}
          >
            Utilize os campos abaixo para encontrar <br /> um atendimento
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.modal}>
          <ValidatorForm onSubmit={onSubmit}>
            <Grid
              container
              className={classes.gridContainer}
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                <TextField
                  onChange={e =>
                    setForm({ ...form, attendance: e.target.value })
                  }
                  select
                  label="Atendente"
                  className={classes.inputFilter}
                  fullWidth
                  value={form.attendance}
                >
                  <MenuItem key="" value="">
                    Selecione...
                  </MenuItem>
                  {attendances?.map(attendance => (
                    <MenuItem key={attendance.id} value={attendance.id}>
                      {attendance.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  onChange={e =>
                    setForm({ ...form, service_status: e.target.value })
                  }
                  select
                  label="Status de Serviço"
                  className={classes.inputFilter}
                  fullWidth
                  value={form.service_status}
                >
                  <MenuItem key="" value="">
                    Selecione...
                  </MenuItem>
                  {serviceStatus?.map(status => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <KeyboardDatePicker
                  clearable
                  value={form.date_start}
                  label="Data Inicial"
                  fullWidth
                  views={['date']}
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date => setForm({ ...form, date_start: date })}
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid item xs={6}>
                <KeyboardDatePicker
                  clearable
                  value={form.date_end}
                  label="Data Final"
                  fullWidth
                  views={['date']}
                  clearLabel="Limpar"
                  invalidDateMessage="Data inválida"
                  autoOk
                  onChange={date => setForm({ ...form, date_end: date })}
                  format="dd/MM/yyyy"
                />
              </Grid>

              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: '1.5em' }}
              >
                <IconButton
                  type="submit"
                  color="primary"
                  fullWidth
                  variant="contained"
                  className={filterStyles.btnSalvar}
                  onClick={handleOpen}
                >
                  <SearchIcon />
                  Pesquisar
                </IconButton>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Grid>
      </BaseFilterModal>
    </>
    // <Tooltip title="Filter list">
    //   <PopupState variant="popover" popupId="demo-popup-popover">
    //     {popupState => (
    //       <div>
    //         <IconButton aria-label="filter list" {...bindTrigger(popupState)}>
    //           <BadgeGlobal form={form} items={items}>
    //             <FilterListIcon />
    //           </BadgeGlobal>
    //         </IconButton>
    //         <Popover
    //           {...bindPopover(popupState)}
    //           anchorOrigin={{
    //             vertical: 'bottom',
    //             horizontal: 'center',
    //           }}
    //           transformOrigin={{
    //             vertical: 'top',
    //             horizontal: 'center',
    //           }}
    //         >
    //           <Box p={2} className={classes.popover}>
    //             <div className={classes.root}>
    //               <ValidatorForm className={classes.form} onSubmit={onSubmit}>
    //                 <Grid
    //                   container
    //                   className={classes.gridContainer}
    //                   spacing={1}
    //                   justify="center"
    //                   alignItems="center"
    //                 >
    //                   <Grid item xs={6}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, attendance: e.target.value })
    //                       }
    //                       select
    //                       label="Atendente"
    //                       margin="dense"
    //                       variant="outlined"
    //                       className={classes.inputFilter}
    //                       fullWidth
    //                       value={form.attendance}
    //                     >
    //                       <MenuItem key="" value="">
    //                         Selecione...
    //                       </MenuItem>
    //                       {attendances?.map(attendance => (
    //                         <MenuItem key={attendance.id} value={attendance.id}>
    //                           {attendance.name}
    //                         </MenuItem>
    //                       ))}
    //                     </TextField>
    //                   </Grid>

    //                   <Grid item xs={6}>
    //                     <TextField
    //                       onChange={e =>
    //                         setForm({ ...form, service_status: e.target.value })
    //                       }
    //                       select
    //                       label="Status de Serviço"
    //                       className={classes.inputFilter}
    //                       margin="dense"
    //                       variant="outlined"
    //                       fullWidth
    //                       value={form.service_status}
    //                     >
    //                       <MenuItem key="" value="">
    //                         Selecione...
    //                       </MenuItem>
    //                       {serviceStatus?.map(status => (
    //                         <MenuItem key={status.id} value={status.id}>
    //                           {status.name}
    //                         </MenuItem>
    //                       ))}
    //                     </TextField>
    //                   </Grid>

    //                   <Grid item xs={6}>
    //                     <KeyboardDatePicker
    //                       clearable
    //                       value={form.date_start}
    //                       label="Data Inicial"
    //                       fullWidth
    //                       margin="dense"
    //                       inputVariant="outlined"
    //                       views={['date']}
    //                       clearLabel="Limpar"
    //                       invalidDateMessage="Data inválida"
    //                       autoOk
    //                       onChange={date =>
    //                         setForm({ ...form, date_start: date })
    //                       }
    //                       format="dd/MM/yyyy"
    //                     />
    //                   </Grid>

    //                   <Grid item xs={6}>
    //                     <KeyboardDatePicker
    //                       clearable
    //                       value={form.date_end}
    //                       label="Data Final"
    //                       fullWidth
    //                       margin="dense"
    //                       inputVariant="outlined"
    //                       views={['date']}
    //                       clearLabel="Limpar"
    //                       invalidDateMessage="Data inválida"
    //                       autoOk
    //                       onChange={date =>
    //                         setForm({ ...form, date_end: date })
    //                       }
    //                       format="dd/MM/yyyy"
    //                     />
    //                   </Grid>

    //                   <Grid item xs>
    //                     <Button
    //                       type="submit"
    //                       color="primary"
    //                       fullWidth
    //                       variant="contained"
    //                       className={classes.submit}
    //                       onClick={popupState.close}
    //                     >
    //                       <SearchIcon />
    //                       {` Filtrar`}
    //                     </Button>
    //                   </Grid>
    //                 </Grid>
    //               </ValidatorForm>
    //             </div>
    //           </Box>
    //         </Popover>
    //       </div>
    //     )}
    //   </PopupState>
    // </Tooltip>
  )
}
