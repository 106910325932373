import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import classNames from 'classnames'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { Link } from 'react-router-dom'
import { TextField } from '@material-ui/core'

import { dateFormatedFullDate, renderRoute } from '../../../util/utils'
import { useSelector } from 'react-redux'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function TimeModalInfo({
  open,
  info,
  handleClose,
  items,
  classes,
  setHoverId,
  updateChildren,
}) {
  const permissions = useSelector(state => state.auth.permissions)

  const buildDates = item => {
    if (item?.measureType?.id === 1) {
      return (
        <>
          <p>
            Início do Serviço:{' '}
            {dateFormatedFullDate(item?.attendance_started_at)}
          </p>
          <p>
            Fim do Serviço: {dateFormatedFullDate(item?.attendance_ended_at)}
          </p>
        </>
      )
    }

    return (
      <>
        <p>
          Data de coleta: {dateFormatedFullDate(item?.attendance_started_at)}
        </p>
        <p>
          Data de entrega: {dateFormatedFullDate(item?.devolution_started_at)}
        </p>
      </>
    )
  }

  function getAttendantName(item) {
    if (item?.devolution_started_at) {
      return item?.devolution_attendant?.name
    }

    return item?.attendant?.name
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        scroll="body"
        onClose={handleClose}
        aria-labelledby="time-modal-info"
        aria-describedby="time-modal-info-description"
      >
        <DialogTitle id="time-modal-info">Informações do item</DialogTitle>
        <DialogContent>
          {items
            ?.filter(item => item.id == info)
            ?.map((item, key) => {
              return (
                <ul
                  key={String(item.id)}
                  className={classNames({
                    [classes.listTimeModal]: info == item.id,
                  })}
                >
                  <li>Serviço: {item?.serviceType?.name}</li>
                  <p>
                    Etiqueta:
                    {renderRoute(['update-bar-code'], permissions) &&
                    item.service_id ? (
                      <TextField
                        value={item.bar_code}
                        onChange={event => {
                          updateChildren(key, event.target.value, 'bar_code')
                        }}
                        placeholder="Etiqueta..."
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    ) : (
                      ` ${item.bar_code || ''}`
                    )}
                  </p>
                  <p>Atendente: {getAttendantName(item)}</p>
                  {buildDates(item)}
                  {item.times && (
                    <>
                      <p>
                        Começou em:{' '}
                        {dateFormatedFullDate(item?.times.started_at)}
                      </p>
                      <p>
                        Finalizou em:{' '}
                        {dateFormatedFullDate(item?.times.ended_at)}
                      </p>
                    </>
                  )}
                  {item.restoration_item_id ? (
                    <p
                      onMouseEnter={() =>
                        setHoverId(item.item_restored?.bar_code)
                      }
                      onMouseLeave={() => setHoverId(null)}
                    >
                      Tapete de restauração: {item.item_restored?.bar_code}
                    </p>
                  ) : (
                    ''
                  )}
                </ul>
              )
            })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            aria-label="finance"
            component={Link}
            to={`/calendar/${items[0]?.service_id}?os=${items[0]?.service_id}`}
            disabled={!items[0]?.service_id}
          >
            Reagendar
          </Button>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(TimeModalInfo)
