/* eslint-disable prefer-template */
/* eslint-disable eqeqeq */
import React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Link,
  Box,
  CardActions,
} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import classnames from 'classnames'
import CountUp from 'react-countup'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link as RouterLink } from 'react-router-dom'

import styles from '../../../resources/theme/global'
import colors from '../../../assets/colors'
import { getDecimalPart } from '../../../util/utils'

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${colors.graylight}`,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  row: {
    width: '100%',
    padding: '8px 12px ',
    '&:last-child': {
      borderRadius: '0 0 10px 10px',
    },
    '&:first-child': {
      borderRadius: '10px 10px 0 0',
    },
  },
  value: {
    fontWeight: 700,
    fontSize: '22px',
    marginRight: theme.spacing(1),
    color: colors.primary,
  },
  differenceValue: {
    color: colors.sucess,
    fontWeight: 700,
    fontSize: '22px',
    marginRight: theme.spacing(1),
  },
  differenceValueNegative: {
    color: colors.error,
    fontWeight: 700,
    fontSize: '22px',
    marginRight: theme.spacing(1),
  },
  noDifference: {
    color: colors.graylight,
    fontWeight: 700,
    fontSize: '22px',
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: '20px',
    color: colors.primary,
    lineHeight: '20px',
    fontWeight: 'normal',
  },
  total: {
    width: '100%',
    borderRadius: 'inherit',
    padding: '4px',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '24px',
    gap: '4px',
    color: '#FFF',
  },
  positive: {
    backgroundColor: colors.sucess,
  },
  negative: {
    backgroundColor: colors.error,
  },
  cardItemLink: {
    color: colors.gray,
    textDecoration: 'underline',
  },
  cents: {
    fontSize: '16px',
    fontWeight: '400',
  },
  footer: {
    height: '28px',
    fontSize: '12px',
    display: 'flex',
    gap: '4px',
    padding: 0,
    paddingRight: '20px',
    color: '#FFF',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'flex-end',
  },
  totalFooter: {
    height: '28px',
    fontWeight: 700,
    fontSize: '16px',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  centsFooter: {
    fontSize: '12px',
    fontWeight: '400',
    padding: 0,
  },
  tipo: {
    backgroundColor: colors.primary,
    fontSize: '10px',
    padding: 0,
    paddingRight: 0,
    height: '28px',
    textAlign: 'center',
    justifyContent: 'center',
  },
}))

const ReceiptCard = ({
  tipo,
  title,
  positive,
  val1,
  val2,
  val3,
  total = 0,
  link1,
  link2,
  link3,
  link4,
  payment = false,
}) => {
  const classes = useStyles()

  const contUp = money => {
    return (
      <CountUp
        end={money}
        duration={0.8}
        // decimals={2}
        decimal=","
        separator="."
      />
    )
  }

  const contUpDecimals = money => {
    if (money === 0) return ',00'
    const decimalPart = getDecimalPart(money)
    return <>{',' + decimalPart}</>
  }

  return (
    <Box className={classes.root}>
      <Box className={[classes.row]}>
        <Typography className={classes.title}>{title}</Typography>
      </Box>

      <Box className={[classes.row]}>
        <Grid container spacing={1}>
          {tipo ? (
            <Grid item xs={12}>
              <Box className={classes.boxValues}>
                <Typography>R$</Typography>
                <Typography className={classnames([classes.value])}>
                  {contUp(val1)}
                  <span className={classes.cents}>{contUpDecimals(val1)}</span>
                </Typography>
              </Box>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Box className={classes.boxValues}>
                  <Typography>R$</Typography>
                  <Typography
                    className={classnames({
                      [classes.differenceValue]: positive,
                      [classes.differenceValueNegative]: !positive,
                    })}
                  >
                    {contUp(val1)}
                    <span className={classes.cents}>
                      {contUpDecimals(val1)}
                    </span>
                  </Typography>
                  <Link
                    component={RouterLink}
                    to={link1}
                    className={classes.cardItemLink}
                  >
                    {payment ? 'Pagos' : 'Recebidos'}
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box className={classes.boxValues}>
                  <Typography>R$</Typography>

                  <Typography className={classes.noDifference}>
                    {contUp(val2)}
                    <span className={classes.cents}>
                      {contUpDecimals(val2)}
                    </span>
                  </Typography>
                  <Link
                    component={RouterLink}
                    to={link2}
                    className={classes.cardItemLink}
                  >
                    A Vencer
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box className={classes.boxValues}>
                  <Typography>R$</Typography>
                  <Typography
                    className={classnames({
                      [classes.differenceValue]: positive,
                      [classes.differenceValueNegative]: !positive,
                    })}
                  >
                    {contUp(val3)}
                    <span className={classes.cents}>
                      {contUpDecimals(val3)}
                    </span>
                  </Typography>
                  <Link
                    component={RouterLink}
                    to={link3}
                    className={classes.cardItemLink}
                  >
                    Vencidos
                  </Link>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      {tipo ? (
        <Box className={[classes.row, classes.footer, classes.tipo]}>
          Recebimentos - Pagamentos
        </Box>
      ) : (
        <Box
          className={[
            classes.row,
            classes.footer,
            positive ? classes.positive : classes.negative,
          ]}
        >
          <Link component={RouterLink} to={link4} style={{ color: '#FFF' }}>
            Total:
          </Link>
          <Typography>R$</Typography>
          <Typography className={classes.totalFooter}>
            {contUp(total)}
            <span className={classes.centsFooter}>{contUpDecimals(total)}</span>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default withStyles(styles)(ReceiptCard)
