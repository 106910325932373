import React, { useCallback, useEffect, useRef, useState } from 'react'
import colors from '../../../assets/colors'
import Chart from 'react-google-charts'
import { Box } from '@material-ui/core'

const PieChart = ({ data = [] }) => {
  const [graphicData, setGraphicData] = useState([])

  useEffect(() => {
    if (data) {
      setGraphicData([])

      data?.map(element => {
        setGraphicData(prevState => [
          ...prevState,
          [element?.name, element?.qtd],
        ])
      })
    }
  }, [data])

  const options = {
    is3D: true,
    // pieHole: 0.4,
    sliceVisibilityThreshold: 0,
    chartArea: {
      width: '100%',
      height: '100%',
    },
    legend: {
      alignment: 'center',
      textStyle: {
        color: colors.primary,
        fontName: 'Roboto',
        fontSize: 14,
      },
    },
    tooltip: { trigger: 'none' },
  }

  return (
    <Box style={{ position: 'relative' }}>
      <Chart
        data={[
          [
            { type: 'string', id: 'Tipo' },
            { type: 'string', id: 'Quantidade' },
          ],
          ...graphicData,
        ]}
        options={options}
        height="230px"
        width="100%"
        chartType="PieChart"
      />
    </Box>
  )
}

export default PieChart
